import Vue from 'vue';
import VueRouter from 'vue-router';
Vue.use(VueRouter);
const router = new VueRouter({
  routes: [
    {
        path:'/',
		name:'/',
        component:()=>import('../views/home/index.vue')
    },
    {
      path:'/Organization',
	  name:'/Organization',
      component:()=>import('../views/organization/indexs.vue')
    },
	{
	  path:'/Details',
	  name:'/Details',
	  component:()=>import('../views/details/details.vue')
	},
	{
	  path:'/login',
	  name:'/login',
	  component:()=>import('../views/login/loginindex.vue')
	},
    // {
    //   path:'/css',
    //   component:()=>import('../views/index.vue')
    // },
    // {
    //   path:'/satrt',
    //   component:()=>import('../views/demo/index.vue')
    // }
  ],
  mode: 'hash',
  // history
  // hash
  base: process.env.BASE_URL,
});

export default router;